import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Landingpage from "./page/Landingpage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Ourstory from "./page/Ourstory";
import Eventspage from "./page/Eventspage";
import Resources from "./page/Resources";
import CommunityStories from "./page/CommunityStories";
import News from "./page/News";
import Successstories from "./page/Successstories";
import Blog from "./page/Blog";
import Partnerships from "./page/Partnerships";
import Help from "./page/Help";
import Donate from "./page/Donate";
import { useEffect } from "react";
import Headermenu from "./components/Headermenu";
import Scrolltotop from "./components/Scrolltotop";
import Privacy from "./page/Privacy";
import Terms from "./page/Terms";
// import Modal from "./components/Modal";

function App() {
  let url = useLocation();
  const navigate = useNavigate();

  console.log(url.hash);

  url = url.pathname.replace("/", "");

  useEffect(() => {
    if (url === "index.html") {
      navigate("/");
    }
  }, [url]);

  return (
    <div className="App relative">
      <Header />
      <Headermenu />
      <Scrolltotop />
      <Routes>
        <Route path={"/"} element={<Landingpage />}></Route>
        <Route path={"/ourstory"} element={<Ourstory />} />
        <Route path={"/events"} element={<Eventspage />} />
        <Route path={"/resources"} element={<Resources />} />
        <Route path={"/communitystories"} element={<CommunityStories />} />
        <Route path={"/news&updates"} element={<News />} />
        <Route path={"/successstories"} element={<Successstories />} />
        <Route path={"/blog"} element={<Blog />} />
        <Route path={"/partnerships"} element={<Partnerships />} />
        <Route path={"/donate"} element={<Donate />} />
        <Route path={"/help&support"} element={<Help />} />
        <Route path={"/privacy"} element={<Privacy />} />
        <Route path={"/terms_and_conditions"} element={<Terms />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
