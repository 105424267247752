import React from "react";

const Exist = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="2xl:container p-4 py-[35px] lg:py-[72px] md:px-10 lg:px-20 w-full flex flex-col items-center gap-10">
        <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
          Why we Exist ?
        </h1>
        <div className=" grid grid-cols-1 md:grid-cols-3 w-full justify-around mt-10 md:mt-[72px] p-4 gap-10 md:gap-5">
          <div className="flex flex-col gap-10 m-auto">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/exist/exist1.png"}
                alt="existimg"
              />
            </div>
            <h1 className="font-seagoe text-060D24 text-20 m-auto">
              Make Connections
            </h1>
          </div>
          <div className="flex flex-col gap-10 m-auto">
            <div className="m-auto">
              <img
                src={process.env.PUBLIC_URL + "/images/exist/exist2.png"}
                alt="existimg"
              />
            </div>
            <h1 className="font-seagoe text-060D24 text-20 m-auto">
              Create a support community.
            </h1>
          </div>
          <div className="flex flex-col gap-10 m-auto">
            <div className="m-auto">
              <img
                src={process.env.PUBLIC_URL + "/images/exist/exist3.png"}
                alt="existimg"
              />
            </div>
            <h1 className="font-seagoe text-060D24 text-20 text-wrap w-4/6 m-auto">
              Participate in group discussions and forums
            </h1>
          </div>
        </div>
        <div className=" md:w-7/12 flex justify-center">
          <p className="text-[#43414D]">
            Social Spectrum exists to bridge the gap for neurodivergent families
            seeking connection and support. We understand that navigating
            neurodiversity can be both rewarding and challenging, and we are
            here to offer a dedicated space where families can find empathy,
            share experiences, and access valuable resources.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Exist;
