import React from "react";

const Herosection = () => {
  return (
    <div className="w-full flex justify-center">
      <div
        className="2xl:container w-full flex justify-center bg-eventbg h-fit min-h-[400px] lg:h-[640px] bg-no-repeat items-center"
        style={{
          backgroundSize: "100% 100%",
          backgroundBlendMode: "overlay",
          backgroundColor: "rgba(225,225,225,0.7)",
        }}
      >
        <div className="text-left w-full md:w-7/12 flex flex-col gap-9 p-5 py-16 md:p-10 lg:p-20">
          <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
            Events Stay Tuned for Exciting Opportunities!
          </h1>
          <p className=" text-060D24 md:text-18">
            At Social Spectrum, we believe in the power of connection and shared
            experiences. Our upcoming events are designed to bring families
            together, foster meaningful discussions, and provide valuable
            resources for navigating neurodiversity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
