import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../Modal";

const Contactus = () => {
  let url = useLocation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  // const [inputval, setInputVal] = useState({
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  //   password: "",
  //   phonenumber: "",
  //   message: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setInputVal({
  //     ...inputval,
  //     [name]: value,
  //   });
  // };

  useEffect(() => {
    if (url.hash === "#contact") {
      setShow(true);
      navigate("/");
    }
    if (url.pathname === "") {
      setShow(false);
    }
  }, [url, setShow]);

  //   console.log(inputval);
  if (window.location.hash == "#Contact") {
    document.getElementById("success").className = "block";
  }

  return (
    <div
      id="contactus"
      className="w-full flex justify-center bg-contact bg-no-repeat bg-cover"
    >
      <div className="flex flex-col lg:flex-row w-full p-4 md:px-20 py-10 md:py-[35px] lg:py-[72px] gap-10 lg:gap-0">
        <div className="flex flex-col w-full lg:w-6/12 gap-10 md:gap-16 text-white">
          <div className="md:text-left text-white">
            <h1 className="font-seagoe text-24 md:text-32 lg:text-48">
              Contact us
            </h1>
          </div>
          <div className="flex flex-col gap-10">
            <div className="flex w-full items-center gap-3 md:gap-6 text-wrap">
              <img
                className="h-6 w-6 md:h-auto md:w-auto"
                src={process.env.PUBLIC_URL + "/images/contactus/mailicon.png"}
                alt="mailicon"
              ></img>
              <p className="text-wrap text-14 md:text-16">
                admin@socialspectrum.org
              </p>
            </div>
            <div className="flex w-full items-center gap-6">
              <img
                className="h-6 w-6 md:h-auto md:w-auto"
                src={
                  process.env.PUBLIC_URL + "/images/contactus/locationicon.png"
                }
                alt="mailicon"
              ></img>
              <p className="text-wrap text-14 md:text-16">
                Vic 3029, Australia.
              </p>
            </div>
          </div>
          <div className="flex gap-3 md:gap-6">
            <Link
              to={
                "https://www.facebook.com/share/vGXJSM3JJYSuxCbW/?mibextid=qi2Omg"
              }
              target="_"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/contactus/facebook.png"}
                alt="facebookicon"
              />
            </Link>
            <Link
              to={"https://www.linkedin.com/company/socialspectrum/"}
              target="_"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/contactus/linkedin.png"}
                alt="linkedinicon"
              />
            </Link>
            <Link
              to={
                "https://www.instagram.com/socialspectrum2024/profilecard/?igsh=MW1jaWhzZnNlcHVzbw=="
              }
              target="_"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/contactus/instagram.png"}
                alt="instagreamicon"
              />
            </Link>
            <Link
              to={"https://x.com/SocialSpectrum4?t=Ul81FPy6reK1z9EEWcIf-Q&s=08"}
              target="_"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/contactus/twitter.png"}
                alt="twittericon"
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-6/12 gap-6 md:gap-12 pb-16">
          <h1 className="text-18 md:text-24 text-E1E7FA text-left">
            Let's Chat, Reach out to us
          </h1>
          <form
            action="/socialspectrum/api/v1/contact"
            method="post"
            className="input_details"
          >
            <div className="md:grid gap-4">
              <div className="w-full flex flex-col gap-3 md:gap-5 text-E1E7FA ">
                <label className="text-E1E7FA text-left">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  className="inputfield"
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-3 md:gap-5 text-E1E7FA mt-4 md:mt-0">
                <label className="text-E1E7FA text-left">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  className="inputfield"
                />
              </div>
              <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 text-E1E7FA md:mt-6">
                <label className="text-E1E7FA text-left">E-mail</label>
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  className="inputfield"
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 text-E1E7FA md:mt-6">
                <label className="text-E1E7FA text-left">Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone Number"
                  className="inputfield"
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 col-span-2 text-E1E7FA md:mt-6">
                <label className="text-E1E7FA text-left">Message</label>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Write your message"
                  className="inputfield min-h-[134px]"
                  required
                ></textarea>
              </div>
              <div className="hidden" id="success">
                <h3 className="">Thank you for contacting us!</h3>
              </div>
            </div>
            <div className="w-full mt-8 flex justify-left">
              <input
                type="submit"
                value="Send Message"
                className="bg-[#1D43B6] px-6 py-4 rounded-[12px] text-white font-bold cursor-pointer"
              />
            </div>
          </form>
          {show ? (
            <Modal content="Message Sent Successfully" duration={2000} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Contactus;
