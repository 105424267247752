import React from "react";

const Herosection = () => {
  return (
    <div
      className="2xl:container w-full h-fit min-h-[400px] lg:h-[700px] ourstorybg"
      style={{ backgroundSize: "100% 100%" }}
    >
      <div className="p-4 py-10 text-center md:p-10 lg:p-20 w-full items-center flex flex-col gap-4">
        {/* <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
          Our Story
        </h1> */}
        <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48 mb-6">
          A Shared Journey of Hope and Connection
        </h1>
        <p className="w-11/12 lg:w-9/12 text-[#43414D] font-medium md:text-18">
          At <b>Social Spectrum</b>, our story is the story of countless
          families navigating the beautiful and complex world of neurodiversity.
          It's a story of love, resilience, and the profound desire to ensure
          that every child feels understood, valued, and supported.
        </p>
        <p className="w-11/12 lg:w-9/12 text-[#43414D] font-medium md:text-18">
          It all began with a simple realization: while each family's experience
          is unique, the challenges we face often overlap-moments of joy,
          frustration,growth, and the search for community. We understood the
          need for a space where these experiences could be shared, where
          families could come together to uplift one another and access the
          tools they need.
        </p>
        <p className="w-11/12 lg:w-9/12 text-[#43414D] font-medium md:text-18">
          That's why we created Social Spectrum - a platform by families, for
          families. We are here to connect,empower, and celebrate the diversity
          that makes every child extraordinary. Together, we're breaking down
          barriers and building a more inclusive and supportive world for
          everyone.
        </p>
        <p className="w-11/12 lg:w-9/12 text-[#43414D] font-medium md:text-18">
          This isn't just our story - it's yours too. Welcome to the Social
          Spectrum family. Let's grow, learn, and thrive together.
        </p>
      </div>
    </div>
  );
};

export default Herosection;
