import React from "react";

const Partnerships = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center partnershipbg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Collaborations for a Cause</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          We're building bridges with organizations, professionals, and advocacy
          groups to amplify our mission. This section will showcase our partners
          an open doors for future collaborations. Together, we can make a
          bigger impact!
        </p>
      </div>
    </div>
  );
};

export default Partnerships;
