import React from "react";
import { Link, useLocation } from "react-router-dom";

const Headermenu = () => {
  const { pathname } = useLocation();
  return (
    <div className="w-full flex justify-center sticky top-[88px] z-50 md:flex hidden">
      <div className="2xl:container w-full flex justify-start px-20 bg-[#060D24] py-2">
        <div className="flex items-center gap-1 text-14 md:text-16 font-bold text-white flex-wrap">
          <Link
            to={"/ourstory"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/ourstory" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Our Story</h1>
          </Link>
          <Link
            to={"/events"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/events" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Events</h1>
          </Link>
          <Link
            to={"/resources"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/resources" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Resources</h1>
          </Link>
          <Link
            to={"/communitystories"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/communitystories" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Community Stories</h1>
          </Link>
          <Link
            to={"/news&updates"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/news&updates" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>News & Updates</h1>
          </Link>
          <Link
            to={"/successstories"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/successstories" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Success Stories</h1>
          </Link>
          <Link
            to={"/blog"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/blog" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Blog</h1>
          </Link>
          <Link
            to={"/partnerships"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/partnerships" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Partnerships</h1>
          </Link>
          <Link
            to={"/donate"}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/donate" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Donate/Support Us</h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Headermenu;
