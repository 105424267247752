import React from "react";

const Help = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center helpbg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Your Support Center</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          Need assistance? Soon, you'll have access to a dedicated section for
          FAQ's, technical support, and emotional guidance. Whether it's
          navigating the app or finding answers to your questions, help will
          always be just a click away.
        </p>
      </div>
    </div>
  );
};

export default Help;
