import React from "react";
import Herosection from "../components/Events/Herosection";
import Events from "../components/Events/Events";

const Eventspage = () => {
  return (
    <div className="w-full flex flex-col items-center gap-10 md:gap-[72px]">
      <Herosection />
      <Events />
    </div>
  );
};

export default Eventspage;
