import React from "react";

const Mission = () => {
  return (
    <div className="2xl:container w-full flex flex-col items-center p-4 pt-10 md:pt-0 md:px-10 lg:px-20 md:py-[35px] lg:py-[72px] bg-mission bg-cover">
      <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
        Our Mission & Vision
      </h1>
      <div className="flex flex-col md:flex-row w-full mt-10 md:mt-16 gap-10 md:gap-0 p-4 md:p-0">
        <div className="w-full md:w-7/12 flex flex-col text-left gap-12">
          <div className="flex flex-col gap-6">
            <h1 className="text-[#3486D9] text-18 md:text-24 font-semibold">
              Vision
            </h1>
            <p className="text-16 text-[#43414D]">
              Our vision at Social Spectrum is to be the leading platform for
              enhancing social well-being and inclusion globally. We aspire to
              build a world where every child, regardless of their social
              challenges, feels valued, understood, and empowered to achieve
              their full potential. Through cutting-edge technology and
              compassionate community engagement, we aim to break down barriers
              and create a more connected, empathetic society.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <h1 className="text-[#3486D9] text-18 md:text-24 font-semibold">
              Mission
            </h1>
            <p className="text-16 text-[#43414D]">
              At Social Spectrum, our mission is to create a more inclusive and
              connected world by empowering families with diverse social needs.
              We strive to provide innovative solutions and resources that
              foster understanding, support and meaningful connections, ensuring
              everyone can thrive in a vibrant and accepting community.
            </p>
          </div>
        </div>
        <div className="w-full md:w-5/12">
          <div className="w-full h-full flex justify-center items-center">
            <img
              src={process.env.PUBLIC_URL + "/images/mission/missionimg.png"}
              alt="missionimg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
