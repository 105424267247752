import React from "react";

const Blog = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center blogbg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Insights and Updates</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          Our blog will bring you fresh perspectives on parenting,
          neurodiversity, and inclusion. Featuring thought-provoking articles,
          tips, and reflections from experts and caregivers, this space is
          designed to keep you informed and inspired.
        </p>
      </div>
    </div>
  );
};

export default Blog;
