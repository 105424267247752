import React from "react";

const Ourcommitment = () => {
  return (
    <div className="w-full flex justify-center p-4 mt-10 md:pt-0 sm:px-10 md:px-10 lg:px-20  pb-[72px]">
      <div className="2xl:container flex flex-col items-center">
        <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
          Our Commitment
        </h1>
        <div className="flex flex-col items-center md:flex-row w-full md:w-11/12 lg:w-9/12 mt-[64px] gap-6">
          <div className="flex flex-col px-4 py-[42px] md:w-4/12 items-center px-4 py-[36px] rounded-[8px] bg-[#EAE5FF]">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/commitment/commit1.svg"}
                alt="icon1"
              ></img>
            </div>
            <h1 className="mt-8 text-24 text-[#060D24] font-semibold">
              Inclusivity
            </h1>
            <p className="mt-6 text-16 text-[#43414D]">
              We are committed to fostering an inclusive environment where every
              child, regardless of their abilities, can feel valued and
              included.
            </p>
          </div>
          <div className="flex flex-col px-4 py-[42px] md:w-4/12 items-center px-4 py-[36px] rounded-[8px] bg-[#E5FDFF]">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/commitment/commit2.svg"}
                alt="icon2"
              ></img>
            </div>
            <h1 className="mt-8 text-24 text-[#060D24] font-semibold">
              Support
            </h1>
            <p className="mt-6 text-16 text-[#43414D]">
              Social Spectrum is not just a platform, it's a supportive
              community. We provide resources, guidelines, and a network of
              parents who understand the journey you're on.
            </p>
          </div>
          <div className="flex flex-col px-4 py-[42px] md:w-4/12 items-center px-4 py-[36px] rounded-[8px] bg-[#FFF0E5]">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/commitment/commit3.svg"}
                alt="icon3"
              ></img>
            </div>
            <h1 className="mt-8 text-24 text-[#060D24] font-semibold">
              Empowerment
            </h1>
            <p className="mt-6 text-16 text-[#43414D]">
              Our goal is to empower parents to actively contribute to their
              child's social development by facilitating connections that go
              beyond the virtual world
            </p>
          </div>
        </div>
        <div className="md:w-6/12 mt-10">
          <p className="text-16 text-[#43414D]">
            Join us on this commitment to create a world where every child,
            regardless of their abilities, experiences the joy and enrichment
            that comes from forming genuine connections. Together, we can make a
            difference in the lives of these extraordinary children and their
            families.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ourcommitment;
