import React from "react";

const Events = () => {
  const data = [
    {
      img: "/images/event/img1.png",
      heading:
        "Live Q&A sessions with specialists in child development and neurodiversity.",
    },
    {
      img: "/images/event/img2.png",
      heading:
        "Virtual workshops focused on parenting tips, advocacy, and education.",
    },
    {
      img: "/images/event/img3.png",
      heading:
        "Community meet-ups to build friendships and connections within our growing Social Spectrum family.",
    },
  ];
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center justify-center bg-[#F4F7FE] py-[72px]">
        <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
          What's Coming Soon?
        </h1>
        <div className="w-full md:w-11/12 flex flex-col md:flex-row py-10 p-4 md:p-20 gap-20 items-center justify-center ">
          {data.map((item, i) => (
            <div key={i} className="flex flex-col m-auto gap-12 items-center">
              <div className="h-auto w-auto">
                <img src={process.env.PUBLIC_URL + item.img} alt="eventimg" />
              </div>
              <p className="font-seagoe text-060D24 text-20">{item.heading}</p>
            </div>
          ))}
        </div>
        <div className="w-9/12 flex w-full justify-center text-16 md:text-18 mb-3 text-060D24">
          <p className="p-4">
            We're working hard behind the scenes to finalize details, so check
            back soon! Better yet, sign up for our newsletter to be the first to
            know about event updates.
          </p>
        </div>
        <div className="flex w-full justify-center text-16 md:text-18 text-060D24">
          <p className="p-4">
            Together we can create a supportive, thriving community.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Events;
