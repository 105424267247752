import React from "react";

const Donate = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center donatebg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Be Part of the Change</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          This section will provide opportunities for you to support our
          mission. Whether through donations, volunteering, or spreading the
          word, you'll be able to help us grow and reach more families in need.
        </p>
      </div>
    </div>
  );
};

export default Donate;
