import React, { useEffect, useState } from "react";

const Modal = ({ content, duration }) => {
  const [onshow, setOnshow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOnshow(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return onshow ? (
    <div className="modal-backdrop">
      <div className="modal-content ">{content}</div>
    </div>
  ) : null;
};

export default Modal;
