import React from "react";

const Successstories = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center successbg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Celebrating Impact</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          We're excited to share stories of families who've found support and
          connection through Social Spectrum. This section will be a testament
          to the positive change we're creating together.
        </p>
      </div>
    </div>
  );
};

export default Successstories;
