import React from "react";

const CommunityStories = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center communitybg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Stories That Inspire</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          We're gathering heartfelt stories from families just like yours. This
          section will celebrate victories, share lessons and remind you that
          you're not alone. Keep an eye out for this powerful space where our
          community comes to life through shared experiences.
        </p>
      </div>
    </div>
  );
};

export default CommunityStories;
