import React from "react";

const Privacy = () => {
  const privacy = () => {
    return (
      <div className="flex flex-col text-left gap-10 scroll-pt-[200px]">
        <div className="text-18 md:text-24 font-semibold">PRIVACY POLICY</div>
        <div className="text-16 font-semibold text-gray-400  text-[#060D24]">
          <p className="text-gray-400">Last updated December 05, 2023</p>
          <div className="flex flex-col text-[#43414D] mt-10 gap-10 text-14 md:text-16">
            <p>
              This privacy notice for Markpro technolgy Pty Ltd (<b>'we'</b>,{" "}
              <b>'us'</b>, or
              <b>'our'</b>), describes how and why we might collect, store, use,
              and/or share (<b>'process'</b>) your information when you use our
              services <b>('Services')</b>, such as when you:
            </p>
            <ul className="list-disc pl-10 flex flex-col gap-5 text-14 md:text-16">
              <li>
                Visit our website at https://www.socialspectrum.org, or any
                website of ours that links to this privacy notice
              </li>
              <li>
                Download and use our mobile application (Social Spectrum), or
                any other application of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p className="text-14 md:text-16">
              <b>Questions or concerns?</b> Reading this privacy notice will
              help you understand your privacy rights and choices. If you do not
              agree with our policies and practices, please do not use our
              Services. If you still have any questions or concerns, please
              contact us at admin@socialspectrum.org.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5 text-14 md:text-16 text-[#43414D]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            SUMMARY OF KEY POINTS
          </h1>
          <p className="font-semibold">
            <i>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </i>
          </p>
          <p>
            <b>What personal information do we process?</b> When you visit, use,
            or navigate our Services, we may process personal information
            depending on how you interact with us and the Services, the choices
            you make, and the products and features you use. Learn more about
            personal information you disclose to us.
          </p>
          <p>
            <b>Do we process any sensitive personal information?</b> We do not
            process sensitive personal information.
          </p>
          <p>
            <b>Do we receive any information from third parties?</b> We do not
            receive any information from third parties.
          </p>
          <p>
            <b> How do we process your information?</b> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about how we process
            your information.
          </p>
          <p>
            <b> What are your rights?</b> Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information. Learn more about your
            privacy rights.
          </p>
          <p>
            <b> How do you exercise your rights?</b> The easiest way to exercise
            your rights is by submitting a data subject access request, or by
            contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          <p>
            <b>
              {" "}
              Want to learn more about what we do with any information we
              collect?
            </b>{" "}
            Review the privacy notice in full.
          </p>
        </div>
        <div className="flex flex-col gap-10 text-[#43414D]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            TABLE OF CONTENTS
          </h1>
          <ol className="list-decimal pl-5 text-[#003afa] text-14 md:text-16 underline space-y-2">
            <li>
              <a href="#link1"> WHAT INFORMATION DO WE COLLECT?</a>
            </li>
            <li>
              <a href="#link2"> HOW DO WE PROCESS YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#link3">
                {" "}
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li>
              <a href="#link4"> HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#link5">DO WE COLLECT INFORMATION FROM MINORS?</a>
            </li>
            <li>
              <a href="#link6"> WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li>
              <a href="#link7">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>
            <li>
              <a href="#link8">
                DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href="#link9">DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </li>
            <li>
              <a href="#link10">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
            </li>
            <li>
              <a href="#link11">
                {" "}
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </a>
            </li>
          </ol>
        </div>
        <div
          id="link1"
          className="flex flex-col gap-10 text-[#43414D] scroll-mt-[150px]"
        >
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            1. WHAT INFORMATION DO WE COLLECT?
          </h1>
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            Personal information you disclose to us
          </h1>
          <p>
            <i>
              <b>In Short:</b> We collect personal information that you provide
              to us.
            </i>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            <b>Personal Information Provided by You.</b> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul className="flex flex-col gap-5 pl-10 list-disc">
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
          </ul>
          <p>
            <b>Sensitive Information.</b> We do not process sensitive
            information.
          </p>
          <p>
            <b>Application Data.</b> If you use our application(s), we also may
            collect the following information if you choose to provide us with
            access or permission:
          </p>
          <ul className="flex flex-col gap-5 pl-10 list-disc">
            <li>
              <i> Geolocation Information.</i> We may request access or
              permission to track location-based information from your mobile
              device, either continuously or while you are using our mobile
              application(s), to provide certain location-based services. If you
              wish to change our access or permissions, you may do so in your
              device's settings.
            </li>
          </ul>
          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
        </div>
        <div id="link2" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h1>
          <p>
            <i>
              <b>In Short:</b> We process your information to provide, improve,
              and administer our Services, communicate with you, for security
              and fraud prevention, and to comply with law. We may also process
              your information for other purposes with your consent.
            </i>
          </p>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul className="flex flex-col gap-5 pl-10 list-disc">
            <li>
              <b>
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </b>{" "}
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li>
              <b>To deliver and facilitate delivery of services to the user.</b>{" "}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <b>To respond to user inquiries/offer support to users.</b> We may
              process your information to respond to your inquiries and solve
              any potential issues you might have with the requested service.
            </li>
            <li>
              <b>To send administrative information to you.</b> We may process
              your information to send you details about our products and
              services, changes to our terms and policies, and other similar
              information.
            </li>
            <li>
              <b>To enable user-to-user communications.</b> We may process your
              information if you choose to use any of our offerings that allow
              for communication with another user.
            </li>
            <li>
              <b>To comply with our legal obligations. </b>We may process your
              information to comply with our legal obligations, respond to legal
              requests, and exercise, establish, or defend our legal rights.
            </li>
          </ul>
        </div>
        <div id="link3" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h1>
          <p>
            <i>
              <b>In Short:</b> We may share information in specific situations
              described in this section and/or with the following third parties.
            </i>
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul className="flex flex-col gap-5 pl-10 list-disc">
            <li>
              <b>Business Transfers</b> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <b>Other Users</b> When you share personal information (for
              example, by posting comments, contributions, or other content to
              the Services) or otherwise interact with public areas of the
              Services, such personal information may be viewed by all users and
              may be publicly made available outside the Services in perpetuity.
              Similarly, other users will be able to view descriptions of your
              activity, communicate with you within our Services, and view your
              profile.
            </li>
          </ul>
        </div>
        <div id="link4" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            4. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h1>
          <p>
            <i>
              <b>In Short:</b> We keep your information for as long as necessary
              to fulfil the purposes outlined in this privacy notice unless
              otherwise required by law.
            </i>
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than three (3) months past the termination of the user's account.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymise such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>
        <div id="link5" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            5. DO WE COLLECT INFORMATION FROM MINORS?
          </h1>
          <p>
            <i>
              <b>In Short:</b> We do not knowingly collect data from or market
              to children under 18 years of age.
            </i>
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at admin@socialspectrum.org.
          </p>
        </div>
        <div id="link6" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            6. WHAT ARE YOUR PRIVACY RIGHTS?
          </h1>
          <p>
            <i>
              <b>In Short:</b> You may review, change, or terminate your account
              at any time.
            </i>
          </p>
          <p>
            <span className="font-semibold underline underline-offset-1">
              Withdrawing your consent:
            </span>{" "}
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section{" "}
            <a href="#link10" className="text-[#003afa] underline">
              'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
            </a>{" "}
            below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            Account Information
          </h1>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul className="flex flex-col gap-5 pl-10 list-disc">
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at admin@socialspectrum.org.
          </p>
        </div>
        <div id="link7" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            7. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h1>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ('DNT') feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognising and
            implementing DNT signals has been finalised. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>
        <div id="link8" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            8. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
          </h1>
          <p>
            <i>
              <b>In Short:</b> You may have additional rights based on the
              country you reside in.
            </i>
          </p>
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            Australia
          </h1>
          <p>
            We collect and process your personal information under the
            obligations and conditions set by Australia's Privacy Act 1988
            (Privacy Act).
          </p>
          <p>
            This privacy notice satisfies the notice requirements defined in the
            Privacy Act, in particular: what personal information we collect
            from you, from which sources, for which purposes, and other
            recipients of your personal information.
          </p>
          <p>
            If you do not wish to provide the personal information necessary to
            fulfill their applicable purpose, it may affect our ability to
            provide our services, in particular:
          </p>
          <ul className="flex flex-col gap-5 pl-10 list-disc">
            <li>offer you the products or services that you want</li>
            <li>respond to or help with your requests</li>
            <li>manage your account with us</li>
            <li>confirm your identity and protect your account</li>
          </ul>
          <p>
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            <a href="#link11" className="text-[#003afa] underline">
              'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?'
            </a>
          </p>
          <p>
            If you believe we are unlawfully processing your personal
            information, you have the right to submit a complaint about a breach
            of the Australian Privacy Principles to the{" "}
            <a
              href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
              className="text-[#003afa] underline"
            >
              Office of the Australian Information Commissioner.
            </a>
          </p>
        </div>
        <div id="link9" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            9. DO WE MAKE UPDATES TO THIS NOTICE?
          </h1>
          <p>
            <i>
              <b>In Short:</b> Yes, we will update this notice as necessary to
              stay compliant with relevant laws.
            </i>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated 'Revised' date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>
        <div id="link10" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h1>
          <p>
            If you have questions or comments about this notice, you may email
            us at admin@socialspectrum.org or contact us by post at:
          </p>
          <p>
            Markpro technolgy Pty Ltd <br />
            812/250 st kilda road
            <br /> melbourne, Victoria 3006
            <br /> Australia
          </p>
        </div>
        <div id="link11" className="flex flex-col gap-10 scroll-mt-[150px]">
          <h1 className="text-[#000] md:text-18 text-16 font-semibold">
            11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h1>
          <p>
            You have the right to request access to the personal information we
            collect from you, change that information, or delete it. To request
            to review, update, or delete your personal information, please fill
            out and submit a{" "}
            <a
              href="https://app.termly.io/notify/fbeb38b5-7c3b-4e49-b42e-900309bab0ca"
              className="text-[#003afa] underline"
              target="_"
            >
              data subject access request.
            </a>
          </p>
          <p>
            This privacy policy was created using Termly's{" "}
            <a
              href="https://termly.io/products/terms-and-conditions-generator/"
              className="text-[#003afa] underline"
              target="_"
            >
              Privacy Policy Generator.
            </a>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex justify-center mb-10">
      <div className=" 2xl:container p-4 md:p-20 flex flex-col w-full h-fit">
        {/* <iframe
          height="100%"
          src={process.env.PUBLIC_URL + "/privacy.html"}
          title="Privacy Policy"
        ></iframe> */}
        {privacy()}
      </div>
    </div>
  );
};

export default Privacy;
