import React from "react";
import Herosection from "../components/landingpage/Herosection";
import Ourcommitment from "../components/landingpage/Ourcommitment";
import Mission from "../components/landingpage/Mission";
import Exist from "../components/landingpage/Exist";
import Contactus from "../components/landingpage/Contactus";

const Landingpage = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <Herosection />
      <Ourcommitment />
      <Mission />
      <Exist />
      <Contactus />
    </div>
  );
};

export default Landingpage;
