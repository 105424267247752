import React from "react";
import Herosection from "../components/ourstory/Herosection";
import Whoweare from "../components/ourstory/Whoweare";

const Ourstory = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <Herosection />
      {/* <Whoweare /> */}
    </div>
  );
};

export default Ourstory;
